if (Object.create === undefined) {
    Object.create = function (o) {
        function F() { }
        F.prototype = o;
        return new F();
    };
}
(function ($) {
    /*
    * DEPENDENCIAS: jquery.cookie.js
    */

    $.fn.slide = function (delay, callback) {
        var delaySet = 0;
        if (delay != undefined && delay != null)
            delaySet = delay;
        this.each(function () {
            if ($(this).hasClass("overlayed"))
                $('.overlay').overlayTap(); //Se usa overlayTap para que no moleste con la funcion overlay de Jquery UI Tools
            if ($(this).is(':visible')) {
                if ($.isFunction(callback))
                    $(this).slideUp(delaySet, callback);
                else
                    $(this).slideUp(delaySet);
            }
            else {
                if ($.isFunction(callback))
                    $(this).slideDown(delaySet, callback);
                else
                    $(this).slideDown(delaySet);
            }
        });
    };

    $.fn.overlayTap = function () {
        $(this).height($('html').height());
        $(this).slide(400);
    }

    $.fn.dropdownMenu = function () {
        var args = arguments[0] || {}; // It's your object of arguments
        $(this).hover(
            function () {
                //show its submenu
                $('ul', this).slideDown(args.delay);
            },
            function () {
                //hide its submenu
                $('ul', this).slideUp(args.delay);
            }
        );
    }

    $.fn.dropdownList = function (options, callback) {
        this.each(function () {
            var ul = $(this);
            var spanTextShow = ul.find('span.textShow');
            var ulSortDrop = ul.find('#bSort-drop');

            ul.find('li a.option').click(function () {
                spanTextShow.html($(this).text());
                ulSortDrop.slideUp(200);
                if (typeof callback == 'function') { // make sure the callback is a function
                    callback.call(this); // brings the scope to the callback
                }
            });
            ul.find('div span.textShow').click(function () {
                ulSortDrop.slide(200);
            });
            ul.mouseleave(function () {
                if (ulSortDrop.is(":visible"))
                    ulSortDrop.slideUp(200);
            });

        });
    }

    $.fn.correctHeight = function () {
        var args = arguments[0] || {}; // It's your object of arguments
        var reset = args.reset;
        var tallest = 0;
        this.each(function () {
            if ($(this).outerHeight() > tallest)
                tallest = $(this).outerHeight();
        });
        if (reset == true)
            tallest = "auto";

        this.height(tallest);
    };

    $.fn.fixBroken = function () {
        return this.each(function () {
            var tag = $(this);
            var alt_img = 'images/tapa_no_disponible.jpg';
            tag.error(function () { // this adds the onerror event to images  
                tag.attr("src", alt_img); // change the src attribute of the image  
                return true;
            });
        });
    };

    $.fn.comprarItem = function () {
        var args = arguments[0] || {}; // It's your object of arguments
        this.click(function () {
            var imagen = $(this).data('imagen');
            var titulo = $(this).data('titulo');
            var precio = $(this).data('precio');
            var id = $(this).data('codigo');
            var cant = ($(this).data('cant') == null) ? 1 : $(this).data('cant');

            var popUp = $('.carritoPopUp');
            popUp.find('img.imagen').attr('src', imagen);
            popUp.find('div.titulo').html(titulo);
            popUp.find('div.precio').html(precio);

            var url = "/func.ajax?a=add&cod=" + id + "&cant=" + cant;

            $.get(url, function (data) {
                if (data.error != null) {
                    alert(data.error)
                }
                else {
                    if ($.tap.LogStatus() && $.cookie("chango_total") != null && $.cookie("chango_cantidad") != null) {
                        $.cookie("chango_cantidad", null);
                        $.cookie("chango_total", null);

                        $.cookie("chango_cantidad", data.cantidad, { path: '/' });
                        $.cookie("chango_total", data.total, { path: '/' });
                    }
                    else {
                        $.cookie("carrito_cantidad", null);
                        $.cookie("carrito_total", null);

                        $.cookie("carrito_cantidad", data.cantidad, { path: '/' });
                        $.cookie("carrito_total", data.total, { path: '/' });
                    }
                    $('.carrito').carritoRefresh({ tipoProducto: 'libro' });
                }
            });

            //$("#carritoAdd").fadeSliderToggle();
            popUp.slide(400);
            setTimeout(function () {
                popUp.slide(300, function () {
                    popUp.find('img.imagen').attr('src', '');
                    popUp.find('div.titulo').html('');
                    popUp.find('div.precio').html('');
                });
            }, 4000);
        });
        return false;
    };

    $.fn.comprarItemDigital = function (contClass) {
        contClass = contClass == null ? 'pop-ebooks' : contClass;
        var popUp = $('.' + contClass);

        popUp.find('.close').click(function () {
            $.tap.HidePopUp(contClass, 300, function () {
                popUp.find('#titulo').html();
                popUp.find('#url').attr('href', '');
            });
        });

        popUp.find('#url').click(function () {
            popUp.slide(200, function () {
                popUp.find('#titulo').html();
                popUp.find('#url').attr('href', '');
            });
        });


        this.click(function () {
            var titulo = $(this).data('titulo');
            var url = $(this).data('url');
            var id = $(this).data('codigo');

            var disponible = popUp.find('.disponible');
            var nodisponible = popUp.find('.no-disponible');

            $.tap.PuedeComparArticuloDigital(id, disponible, nodisponible);

            popUp.find('#titulo').html(titulo);
            popUp.find('#url').attr('href', url);
            $.tap.ShowPopUp(contClass, 300);
        });
    };

    $.fn.carritoRefresh = function () {
        var args = arguments[0] || {}; // It's your object of arguments
        var currency = (args.currency != null) ? args.currency : "AR$";
        var tipoProducto = (args.tipoProducto != null) ? args.tipoProducto : "Libro";
        var cantidad;
        var precio;

        var containerCantidad = $(this).find('.cantidad');
        var containerTipoProducto = $(this).find('.tipoProducto');
        var containerMoneda = $(this).find('.moneda');
        var containerTotal = $(this).find('.total');
        var containerCerrarCompra = $(this).find('.cerrarCompra');

        if ($.tap.LogStatus()) {
            if ($.cookie("chango_total") != null && $.cookie("chango_cantidad") != null) {
                cantidad = $.cookie("chango_cantidad");
                precio = $.cookie("chango_total");
            }
            else {
                cantidad = 0;
                precio = 0;
            }
        }
        else {
            cantidad = $.cookie("carrito_cantidad");
            precio = $.cookie("carrito_total");
        }
        if (cantidad == null)
            cantidad = 0;
        if (precio == null)
            precio = '0.00';
        var leyenda = '';

        if (cantidad == 0) {
            $(this).removeClass("cartFull");
            $(this).addClass("cartEmpty");
            containerCerrarCompra.attr("href", "/carrito.aspx");
        }
        else {
            $(this).addClass("cartFull");
            $(this).removeClass("cartEmpty");
            containerCerrarCompra.attr("href", "/carrito.aspx");

        }

        if (window.location.href.indexOf("carrito.aspx") >= 0) {
            containerCerrarCompra.attr("href", "/carrito.aspx?cerrarcompra=si");
            containerCerrarCompra.addClass("finalizar");
            var finalizarTitle = "Finalizar la compra";
            if (containerCerrarCompra.attr('data-title') != undefined)
                finalizarTitle = containerCerrarCompra.attr('data-title');
            containerCerrarCompra.attr("title", finalizarTitle);
        }

        if (cantidad > 1)
            tipoProducto = tipoProducto + "s";

        containerCantidad.html( cantidad );
        containerTipoProducto.html(tipoProducto);
        containerMoneda.html(currency);
        containerTotal.html(precio);
    };

    $.fn.center = function (altura) {
        if (!altura)
            altura = 50;

        this.css("position", "absolute");
        if (this.height() > $(window).height())
            this.css("top", altura + $(window).scrollTop() + "px");
        else
            this.css("top", ($(window).height() - this.height()) / 2 + $(window).scrollTop() + "px");
        this.css("left", ($(window).width() - this.outerWidth()) / 2 + $(window).scrollLeft() + "px");
        return this;
    }

    $.fn.watermark = function () {
        var args = arguments[0] || {}; // It's your object of arguments
        var color = (args.color != null) ? args.color : '#a8a8a8';
        this.each(function () {
            var textWatermark = $(this).data("watermark");

            if (textWatermark != null && textWatermark != undefined) {
                if ($.trim($(this).val) == '') {
                    $(this).css('color', color);
                    $(this).val(textWatermark);
                }

                $(this).bind('focus', function () {
                    if ($(this).val() == textWatermark) {
                        $(this).val('');
                        $(this).css('color', '');
                    }
                });
                $(this).bind('blur', function () {
                    if ($.trim($(this).val()) == '') {
                        $(this).val(textWatermark);
                        $(this).css('color', color);
                    }
                });
                if ($.trim($(this).val()) == '' && !$(this).is(":required")) {
                    $(this).val(textWatermark);
                    $(this).css('color', color);
                }
            }
        });
    };

    $.fn.addToBookmark = function () {
        this.each(function () {
            $(this).click(function (e) {
                e.preventDefault(); // this will prevent the anchor tag from going the user off to the link
                var bookmarkUrl = (this.href == null || this.href == '#' || this.href == '') ? window.location.href : this.href;
                var bookmarkTitle = (this.title == null || this.title == '') ? $('title').html() : this.title;

                if (window.sidebar) { // For Mozilla Firefox Bookmark
                    window.sidebar.addPanel(bookmarkTitle, bookmarkUrl, "");
                } else if (window.external || document.all) { // For IE Favorite
                    window.external.AddFavorite(bookmarkUrl, bookmarkTitle);
                } else if (window.opera) { // For Opera Browsers
                    $(this).attr("href", bookmarkUrl);
                    $(this).attr("title", bookmarkTitle);
                    $(this).attr("rel", "sidebar");
                } else { // for other browsers which does not support
                    alert('Please hold CTRL+D and click the link to bookmark it in your browser.');
                    return false;
                }
            });
        });
    };

    $.fn.logOut = function () {
        this.each(function () {
            $(this).click(function () {
                $.tap.LogOut();
                return false;
            });
        });
    };

    $.fn.changeLang = function () {
        $(this).click(function () {
            var current_date = new Date;
            var idiomaPrederminado = $(this).data('idioma-predeterminado');
            if ($.cookie("CulturePref") == null) //si no existe cookie designa por defecto español
            {
                $.cookie("CulturePref", idiomaPrederminado, { path: '/' });
            }
            else //si existe la cookie la cambia
            {
                idioma = $.cookie("CulturePref");
                if (idioma == "EN-US")
                    idioma = idiomaPrederminado;
                else
                    idioma = "EN-US";

                $.cookie("CulturePref", idioma, { path: '/' });
            }
            window.location = window.location.href;
            return false;
        });
    };

    $.tap = function () { };

    $.tap.LogStatus = function () {
        if ($.cookie("userToken") != null)
            return true;
        else
            return false;
    };

    $.tap.LogOut = function () {
        $.cookie("userToken", null, { path: '/' });
        $.cookie("idcarrito", null, { path: '/' });
        window.location.reload();
    };

    $.tap.compartirEnPinterest = function (url, descripcion, imagen) {
        window.open('http://www.pinterest.com/pin/create/button/?url=' + url + '&media=' + imagen + '&description=' + descripcion);
    }

    $.tap.compartirEn = function (url, titulo, serv) {
        switch (serv) {
            case 'fb':
                window.open('http://www.facebook.com/sharer.php?u=' + url + '&t=' + titulo, 'ventanacompartir', 'toolbar=0, status=0, width=650, height=450');
                break;
            case 'tw':
                window.open('http://twitter.com/share?url=' + url + '&via=AddThis&text=' + titulo, 'ventanacompartir', 'toolbar=0, status=0, width=650, height=450')
                break;
            case 'gp':
                window.open('https://plus.google.com/share?url=' + url + '&t=' + titulo, 'ventanacompartir', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
                break;
            case 'linkedIn':
                window.open('https://www.linkedin.com/cws/share?url=' + url + '&title=' + titulo);
                break;
        }
        return false;
    }

    $.tap.compartirEnFB = function (url, titulo, descripcion, imagen, appId, caption) {

        //Debe estar ya seteado el FB
        FB.init({
            appId: appId,                        // App ID from the app dashboard
            status: true,                                 // Check Facebook Login status
            xfbml: true                                  // Look for social plugins on the page
        });

        caption = caption || titulo;//si caption no está definido, toma el titulo
        // calling the API ...
        var obj = {
            method: 'feed',
            link: url,
            picture: imagen,
            name: titulo,
            caption: caption,
            description: descripcion
        };

        FB.ui(obj, null);
    }

    $.tap.PuedeComparArticuloDigital = function (diPubId, divPuede, divNoPuede) {
        var url = "/func.ajax?a=puedeComprarArticuloDigital&diPubId=" + diPubId;
        $.ajax(
        {
            url: url,
            async: false,
            success: function (data) {
                if (data.error != null) {
                    console.log(data.error);
                }
                else {
                    if (divPuede && divNoPuede) {
                        if (data.Value) {
                            // puedecomprar
                            divPuede.show();
                            divNoPuede.hide();
                        }
                        else {
                            divPuede.hide();
                            divNoPuede.show();
                        }
                    }
                }
            }
        });
    }

    $.tap.GetPaisPorIp = function () {
        var url = "/func.ajax?a=getPaisPorIp";
        var pais = "NULL";
        $.ajax(
        {
            url: url,
            async: false,
            success: function (data) {
                if (data.error != null) {
                    console.log(data.error);
                }
                else {
                    pais = data.mensaje;
                }
            }
        });

        return pais;
    }

    $.tap.ShowPopUp = function (popClass, delay, callback) {
        $('.' + popClass).slide(delay, function () { if (callback != null) callback(); $('.' + popClass).center(); });
    }

    $.tap.HidePopUp = function (popClass, delay, callback) {
        $('.' + popClass + ':visible').slide(delay, callback);
    }

    $.tap.DropdownListSelectByValue = function (dropdownList, selectedValue) {
        if (selectedValue && $.tap.DropdownListOptionExistsByValue(dropdownList, selectedValue)) {
            // si tiene una seleccionada y existe en el select, la selecciono
            dropdownList.val(selectedValue);
            dropdownList.change();
        }
        else if (dropdownList.find("option").length <= 2) {
            // si tiene dos o una opción, selecciono la última o la única
            dropdownList.val(dropdownList.find("option:last-child").val());
            dropdownList.change();
        }
    }

    $.tap.DropdownListOptionExistsByValue = function (dropdownList, selectedvalue) {
        return dropdownList.find("option[value='" + selectedvalue + "']").length > 0;
    }

    $.tap.DropdownListClear = function (dropdownList) {
        dropdownList.find("option").remove().end();
    }

    $.tap.DropdownListLoad = function (dropdownList, items, nameAttribute, valueAttribute, defaultAttribute, selectedValue) {
        $.tap.DropdownListClear(dropdownList); // limpio el dropdownlist

        if (defaultAttribute) {
            var optionDefault = new Option(defaultAttribute, "-1");
            $(optionDefault).html(defaultAttribute);
            dropdownList.append(optionDefault); // inserto el item por defecto
        }

        if (!items)
            items = [];

        // cargo el dropdownlist
        $.each(items, function (index, value) {
            var option = new Option(value[nameAttribute], value[valueAttribute]);
            $(option).html(value[nameAttribute]);
            dropdownList.append(option);
        });

        if (selectedValue)
            $.tap.DropdownListSelectByValue(dropdownList, selectedValue); // selecciono el valor elegido
    }

    $.tap.LoadAndHideIfEmpty = function (value, controlToLoad, controlToHide) {
        if (value && controlToLoad) {
            controlToLoad.html(value);
            controlToHide.show();
        }
        else if (controlToHide) {
            controlToHide.hide();
        }
    }

    // DivModalManager
    $.tap.DivModalManager = Object.create(null);
    $.tap.DivModalManager.Background = null;
    $.tap.DivModalManager.ElementsToHide = null;
    $.tap.DivModalManager.ModalContainer = null;
    $.tap.DivModalManager.ModalContainerContent = null;
    $.tap.DivModalManager.ModalBag = null;
    $.tap.DivModalManager.Velocity = 'fast';

    $.tap.DivModalManager.ShowModal = function (divToPopUp) {
        this.ModalContainerContent.html(divToPopUp)

        // oculto los elementos de flash por defecto, porque transpasan el fondo (no se pueden cubrir)
        if (this.flashElements) {
            $.each(this.ElementsToHide, function (index, value) { value.css('visibility', 'hidden'); });
        }

        if (this.Background) {
            this.Background.show(this.Velocity); // muestro el fondo
        }

        var thisForX = this;
        this.ModalContainer.show(this.Velocity, function () { thisForX.CenterAll(); });
    }

    $.tap.DivModalManager.HideModal = function () {
        // vuelvo a mostrar los elementos flash
        if (this.flashElements) {
            $.each(this.ElementsToHide, function (index, value) { value.css('visibility', 'visible'); });
        }

        if (this.Background) {
            this.Background.hide(this.Velocity); // oculto el fondo
        }

        this.ModalContainer.hide(this.Velocity);
        var theDivToPopUp = this.ModalContainerContent.children();
        this.ModalBag.append(theDivToPopUp);
    }

    $.tap.DivModalManager.CenterAll = function () {
        if (this.Background) {
            this.Background.css("height", $(document).height() + "px");
        }

        if ($.tap.IsMobile()) {
            this.ModalContainer.css("top", $(window).scrollTop() + "px");
        }
        else {
            this.ModalContainer.center(0);
        }
    }

    $.tap.CreateDivModal = function (modalContainer, modalBag, background, elementsToHide) {
        theModule = Object.create($.tap.DivModalManager);
        theModule.ModalContainer = modalContainer;
        theModule.ModalBag = modalBag;
        theModule.Background = background;
        theModule.ElementsToHide = elementsToHide;
        theModule.ModalContainer.find("a.close").click(function () { theModule.HideModal(); });
        theModule.ModalContainerContent = theModule.ModalContainer.find("div.divModalContainerContent");
        theModule.CenterAll();
        return theModule;
    }
    // Fin DivModalManager

    // Funciones Responsive
    $.tap.IsMobile = function () {
        var mq = window.matchMedia("(max-width: 480px)");
        return mq.matches;
    }

    $.tap.IsTablet = function () {
        var mq = window.matchMedia("(min-width: 481px) and (max-width: 1024px)");
        return mq.matches;
    }


})(jQuery);
