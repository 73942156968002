(function ($) {

    $.fn.center = function (altura) {
        if (!altura)
            altura = 50;

        this.css("position", "absolute");
        if (this.height() > $(window).height())
            this.css("top", altura + $(window).scrollTop() + "px");
        else
            this.css("top", ($(window).height() - this.height()) / 2 + $(window).scrollTop() + "px");
        this.css("left", ($(window).width() - this.outerWidth()) / 2 + $(window).scrollLeft() + "px");
        return this;
    }
    $.fn.comprarItem = function () {
        var args = arguments[0] || {}; // It's your object of arguments
        var contClass = args.carritoClass == null ? 'carritoPopUp' : args.carritoClass;
        this.click(function () {
            var imagen = $(this).data('imagen');
            var titulo = $(this).data('titulo');
            var precio = $(this).data('precio');
            var autor = $(this).data('autor');
            var id = $(this).data('codigo');
            var cant = ($(this).data('cant') == null) ? 1 : $(this).data('cant');
            var tipoProducto = 'item';
            var modal = $("#modalCarrito");
            console.log(precio);
            console.log(autor);
            console.log(id);

            console.log(titulo);
            modal.find('#imagen').attr('src', imagen);
            modal.find('#titulo').html(titulo);
            modal.find('#precio').html(precio);

            modal.find('#autor').html(autor);
            modal.find('#cantidad').html(cant);

            if (cant > 1)
                tipoProducto = tipoProducto + "s";

            modal.find('#tipoProducto').html(tipoProducto);
            //popUp.find('.total').slide({ delay: 100 });
            var url = "/func.ajax?a=add&cod=" + id + "&cant=" + cant;

            $.get(url, function (data) {
                if (data.error != null) {
                    console.log(data.error);
                }
                else {
                    if ($.tap.LogStatus() && $.cookie("chango_total") != null && $.cookie("chango_cantidad") != null) {
                        $.cookie("chango_cantidad", null);
                        $.cookie("chango_total", null);

                        $.cookie("chango_cantidad", data.cantidad);
                        $.cookie("chango_total", data.total);
                    }
                    else {
                        $.cookie("carrito_cantidad", null);
                        $.cookie("carrito_total", null);

                        $.cookie("carrito_cantidad", data.cantidad);
                        $.cookie("carrito_total", data.total);
                    }
                    modal.find('#cantidadTotal').html( + data.cantidad );
                    modal.find('#precioTotal').html('' + data.total);

                    tipoProducto = 'item';
                    if (data.cantidad > 1)
                        tipoProducto = tipoProducto + "s";
                    modal.find('#tipoProductoTotal').html(tipoProducto);
                    modal.find('.total').slide(200);
                    $('.carrito').carritoRefresh({ tipoProducto: 'ITEM', currency: '$' });

                    if (typeof fbq === "function" && fbPixel !== '') {
                        var fbAddToCart = {
                            content_name: titulo,
                            content_ids: [id],
                            content_type: 'product',
                        };
                        fbq('track', 'AddToCart', fbAddToCart);
                        console.log('fbEvent:AddToCart', fbAddToCart);
                    }
                }
            });
            modal.modal('show');


            modal.on('hidden.bs.modal', function (e) {
                modal.find('#imagen').attr('src', '');
                modal.find('#titulo').html('');
                modal.find('#precio').html('');
                modal.find('#autor').html('');
                modal.find('#cantidad').html('0');
                modal.find('#tipoProducto').html('');
                modal.find('.total').hide();
            });
        });
        return false;
    };

    $.tap.PuedeComparArticuloDigital = function (diPubId, modalId, urlCheckout) {
        if (diPubId == 'DI9P9781622637331')
            window.location.href = urlCheckout;

        var url = "/func.ajax?a=puedeComprarArticuloDigital&diPubId=" + diPubId;
        $.ajax(
            {
                url: url,
                async: false,
                success: function (data) {
                    if (data.error != null) {
                        console.log(data.error);
                    }
                    else {
                        if (data.Value) {
                            $('#' + modalId + ' #noDisponible').hide();
                            $('#' + modalId + ' #confirmar').show();
                            $('#' + modalId).modal('show');
                            $('#' + modalId + ' #toCheckout').attr('href', urlCheckout);
                        }
                        else {
                            $('#' + modalId + ' #noDisponible').show();
                            $('#' + modalId + ' #confirmar').hide();
                            $('#' + modalId).modal('show');
                        }
                    }
                }
            });
    };

    $.fn.comprarItemDigital = function () {
        this.click(function () {
            var titulo = $(this).data('titulo');
            var url = $(this).data('url');
            var id = $(this).data('codigo');

            var imagen = $(this).data('imagen');
            var precio = $(this).data('precio');
            var autor = $(this).data('autor');
            var id = $(this).data('codigo');
            var tipoProducto = 'item';

            console.log(imagen);
            var idModal = 'modalDigital';
            var modal = $("#" + idModal);
            modal.find('#imagen').attr('src', imagen);
            modal.find('#titulo').html(titulo);
            modal.find('#precio').html(precio);

            modal.find('#autor').html(autor);
          //  modal.find('#cantidad').html(cant);

            $('#' + idModal + ' .modal-body span#titulo').html(titulo);

            $.tap.PuedeComparArticuloDigital(id, idModal, url);
        });
    };

   

    $.fn.dropdownList = function (option, callback) {
        this.each(function () {
            var ul = $(this);
            var spanTextShow = ul.find('span.textShow');
            var ulSortDrop = ul.find('#bSort-drop');

            if (option != null) {
                ul.find('li a.option[data-por="' + option + '"]').click();
            }
            else {

                ul.find('li a.option').click(function () {
                    spanTextShow.html($(this).text());
                    ulSortDrop.slideUp(200);
                    if (typeof callback == 'function') { // make sure the callback is a function
                        callback.call(this); // brings the scope to the callback
                    }
                });
                ul.find('div span.textShow').click(function () {
                    ulSortDrop.slide(200);
                });
                ul.mouseleave(function () {
                    if (ulSortDrop.is(":visible"))
                        ulSortDrop.slideUp(200);
                });
            }

        });
    }

    $.fn.wishList = function () {
        var args = arguments[0] || {}; // It's your object of arguments
        var targetType = args.tipoProducto == null ? 'libro' : args.tipoProducto;
        var contClass = args.contClass == null ? 'pop-gral' : args.contClass;
        var titaccion = args.titulo == null ? 'Agregar a mis lista de deseos' : args.titulo;

        var popUp = $('.' + contClass);
        popUp.find('.close').click(function () {
            $.tap.HidePopUp(contClass, 300);
        });
        this.click(function () {
            popUp.find('div.pop-container').html('');
            var posClick = $(this).offset();
            var type = "favoritos";
            var returl = encodeURI(window.location.href);
            var targetId = $(this).data('isbn');
            var targetTitle = $(this).data('titulo');

            var tot = Auth.Url + '?commerceid=' + Auth.IdComercio;
            tot += '&type=' + type;
            tot += '&retUrl=' + returl;
            tot += '&targetId=' + targetId;
            tot += '&targetTitle=' + targetTitle;
            tot += '&targetType=' + targetType;
            tot += '&logoUrl=' + Auth.Logo;
            tot += '&reloadUrl=' + Auth.ReloadUrl;
            tot += '&cssAuth=' + Auth.Css;
            tot += '&idcomercioorigen=' + Auth.IdComercio;
            tot += '&usertoken=' + Auth.UserToken;
            tot += '&dominio=' + Auth.Dominio;
            tot += '&titaccion=' + titaccion;

            var iframe = $("<iframe id='iframeAuth'>");
            iframe.attr('src', tot);
            iframe.appendTo(popUp.find('div.pop-container'));
            iframe.height('236px');
            $.tap.ShowPopUp(contClass, 0);

        });
    }

    $.fn.conversor = function () {
        var args = arguments[0] || {}; // It's your object of arguments
        var contClass = args.contClass == null ? 'pop-gral' : args.contClass;
        var modal = $("#modalGral");

        $(this).click(function (e) {
            modal.find('.modal-body').html('');
            var url = $(this).attr("href");
            var div = $("<div class='embed-responsive'>");
            var iframe = $("<iframe id='conversor'>");
            iframe.attr('src', url);
            iframe.appendTo(div);
            div.appendTo(modal.find('.modal-body'));


            iframe.height('465px');

            modal.modal('show');

            modal.on('hidden.bs.modal', function (e) {
                modal.find('.modal-body').html('');
            });

            return false;
        });
    }


    $.fn.overflowShowMore = function () {
        this.each(function () {
            var elem = $(this);
            var showText = elem.parent().find('.overflow-text');
            var minHeight = showText.data('min-height');
            var actualHeight = showText.height();
            showText.attr("data-max-height", actualHeight);

            if (actualHeight > minHeight) {

                showText.css('overflow', 'hidden');
                showText.height(actualHeight);
                showText.height(minHeight);

                elem.find('.overflow-show-more').show();
                elem.find('.overflow-show-less').hide();
                elem.show();

                elem.find('.overflow-show-more, .overflow-show-less').click(function () {
                    if ($(this).hasClass('overflow-show-more')) {
                        showText.height(actualHeight);
                        $(this).fadeOut(300, function () {
                            $('.overflow-show-less').fadeIn(300);
                        });

                    }
                    else {
                        showText.height(minHeight);
                        $(this).fadeOut(300, function () {
                            $('.overflow-show-more').fadeIn(300);
                        });
                    }
                });
            }
        });
    }


    // Funciones Responsive
    $.tap.IsMobile = function () {
        var mq = window.matchMedia("(max-width: 767px)");
        return mq.matches;
    }

    $.tap.IsTablet = function () {
        var mq = window.matchMedia("(min-width: 768px) and (max-width: 991px)");
        return mq.matches;
    }

})(jQuery);
