(function ($) {
    /*Depende de:
    /* jquery.validate */
    /* jquery.validate.messages_es */
    /**/
    $.fn.suscribe = function () {
        this.click(function () {

            var suscrbibeContainer = $(this).parents('.mail');
            var resultContainer = suscrbibeContainer.find('.result');
            var dataContainer = suscrbibeContainer.find('.data');
            var email = $.trim(dataContainer.find('input[type=email]').val());
            var idtema = $.trim(dataContainer.find('input[type=hidden]').val());
            console.log(this);

            $(this).html("Procesando...");

            if (email == "") {

                resultContainer.html("Debe ingresar su e-mail");
                resultContainer.fadeIn(300);
                return false;
            }
            else
            {
                var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);
                // alert( pattern.test(emailAddress) );
                if (pattern.test(email) == false) {
                    resultContainer.html("E-mail inválido");
                    resultContainer.fadeIn(300);
                    return false;
                }

            }

            //if (!dataContainer.find('input[type=email]').valid())
            //    return false;

            //var $btn = $(this).button('loading');

            suscrbibeContainer.addClass('process');
            $.ajax({
                type: "GET",
                contentType: "application/json; charset=utf-8",
                url: "/ServiceAcciones.svc/suscribirEmail",
                data: 'email=' + email + '&idtema=' + idtema,
                dataType: "json",
                processData: false,
                cache: false,
                success: function (result) {
                    resultContainer.html(result);
                    dataContainer.fadeOut(500, function () { suscrbibeContainer.removeClass('process').addClass('success'); resultContainer.fadeIn(300) });
                },
                error: function (result) {
                    console.log(result);
                }
            });

            return false;
        });
    }
})(jQuery);